import React from "react";
import { graphql } from "gatsby";
import GenericPost from "./GenericPost";

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: { eq: $id }) {
      id
      publishedAt
      genres {
        _id
        title
      }
      mainImage {
        ...SanityImage
        alt
      }
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      authors {
        _key
        author {
          image {
            alt
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
        }
      }
      keywords
      curatedLinks {
        _key
        reference {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
      tags {
        tag {
          title
        }
      }
    }
  }
`;

const BlogPostTemplate = ({ data, errors, pageContext }) => {
  const { nextItem, previousItem } = pageContext;
  return (
    <GenericPost
      post={data.post}
      errors={errors}
      nextItem={nextItem}
      previousItem={previousItem}
      tracking={`POST: ${data.post.title}`}
    />
  );
};

export default BlogPostTemplate;
